.App {
  text-align: center;
}

.tableRowHeader {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  gap: 1em;
  font-size: 0.5em;
  border: 1px solid black;
  align-items: center;
}

.tableRow {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  gap: 1em;
  font-size: 1em;
  font-weight: 400;
  align-items: center;
  border: 1px solid rgba(0,0,0,0.2);
}

.filterDropDown {
  width: 15em;
  text-align: center;
}

.publicUrl {
  height: 2em;
  width: 80%;
  margin-left: 1em;
  margin-right: 1em;
}

.fileTypeIcon {
  height: 20px;
  width: 20px;
}

/* 
V2 Styles
*/

.mainContainer {
  height: 100vh;
  width: 100vw;
  font-family: 'Metropolis';
}

.spinnerContainer {
  display: grid;
  place-items: center;
  position: fixed;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #5A4BDA;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.invisible {
  display: none;
}

.filtersContainer {
  height: fit-content;
  display: flex;
  gap: 1em;
  padding: 10px;
  justify-content: end;
  align-items: center;
}

.documentCardContainer {
  height: calc(100%);
}

.centerName {
  font-family: 'Metropolis';
  font-weight: 600;
}

.documentCard {
  display: grid;
  grid-template-columns: 5fr 1fr 6fr 1fr 4fr 2fr;
  justify-content: space-evenly;
  align-items: center;
  height: 5em;
  outline: 1px solid rgba(0,0,0,0.2);
  gap: 3em
}

.stackedSpan {
  display: flex;
  gap: 15px;
  place-items: center;
  justify-content: center;
}

.stackedSpan img {
  height: 20px;
  cursor: pointer;
}

.button {
  width: 100%;
  background-color: white;
  color: #5a4bda;
  border: 0px;
  outline: 0px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
  font-weight: 500;
  font-family: 'Metropolis';
  transition: all 0.2s ease;
  margin-right: 2em;
}

.button:hover {
  background-color: #5a4bda;
  color: white;
}

.thumbnail {
  height: 5em;
}

.pTagNoMargins {
  margin: 0px;
}

.statusSpan {
  display: grid;
  place-items: center;
  grid-template-rows: 1fr 1fr;
  height: 100%;
}

.fileName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  max-width: 15em;
  text-align: center;
}

.statusIndicators {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-evenly;
  padding-left: 10px;
  padding-right: 10px;
}

.statusDialog {
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 5px;
}

.centerDetailsSpan {
  padding-left: 10px;
}

.fileNameTypeSpan {
  display: flex;
  align-items: baseline;
  gap: 10px
}

.uploadedTextSpan {
  font-size: 12px;
}

.fileTypeContainer {
  display: flex;
  gap: 2em;
  align-items: center;
  justify-content: space-between;
}

.loadMoreContainer {
  padding: 2em;
  display: grid;
  place-items: center;
}

.loadMoreButton {
  width: fit-content;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  cursor: pointer;
}

.button {
  cursor: pointer;
}

.selectOneButton {
  width: fit-content;
  padding-left: 1em;
  padding-right: 1em;
  margin-right: 0px;
}

.selectOneSpan {
  display: grid;
  place-items: center;
  height: 4em;
}

.selectOneSpan p {
  margin: 0px;
}

.selectOneScheduleModal {
  top: 50%;
  height: fit-content;
  width: fit-content;
}

.button:disabled {
  opacity: 0.5;
}

.loginCard {
  border-radius: 1em;
  height: fit-content;
  padding-top: 2em;
  padding-bottom: 2em;
  gap: 1em
}

p {
  text-decoration: none !important;
}

.downloadCsv {
  width: fit-content;
  margin-right: 0em;
}

.multiScheduleModal {
  height: 500px;
  max-width: 600px;
  width: 100%;
  border-radius: 1em;
  box-shadow: 2px 2px 15px rgba(0,0,0,0.3);
  background-color: white;
}

.multiScheduleModalContainer {
  display: block;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.15);
  z-index: 100;
  display: grid;
  place-items: center;
}

.multiScheduleModal {
  display: grid;
  grid-template-rows: 1fr 10fr;
  place-items: center;
  align-items: start;
  width: 100%;
}

.multiScheduleModal p {
  font-weight: 500;
  font-size: 20px;
}

.scheduleCard p {
  font-weight: 300;
  font-size: 15px;
  height: fit-content;
  color: #000
}

.scheduleCard {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1em;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
  border-radius: 0.5em;
  padding-left: 1em;
}

.schedulesContainer {
  max-height: calc(90%);
  height: fit-content;
  max-width: 100%;
  overflow-y: scroll;
  display: grid;
  justify-content: center;
  align-items: start;
  width: 550px;
  gap: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 25px;
  padding-right: 25px;
}

.scheduleCard button {
  border: none;
  box-shadow: none;
  background-color: #5A4BDA;
  border-radius: 0px 0.5em 0.5em 0px;
  color: #fff;
}

.scheduleCard button:hover {
  scale: 1.05;
  border-radius: 0.5em;
} 

.findAllButton {
  height: fit-content;
  width: fit-content;
}

.titleInput {
  height: 2.5em;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.15);
  padding-left: 1em;
  padding-right: 1em;
}

.titleInput:focus {
  outline: none;
}

.titleInput:disabled {
  opacity: 0.6;
}

.editContainer {
  width: 100%;
  display: grid;
  place-items: center;
  gap: 1em
}